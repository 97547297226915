import { Image } from "components/Image"

import './downloadApp.module.scss';

export const DownloadApp = (): JSX.Element => {
    return (
        <div className={'download-app'}>
            <a id={'downloadApple'} href={'https://apps.apple.com/app/id1597443836'}>
                <Image src={'/images/assets/apple_appstore.png'} />
            </a>
            <a id={'downloadAndroid'} href={'https://play.google.com/store/apps/details?id=nl.thetalentpool.app&'}>
                <Image src={'/images/assets/google_play.png'} />
            </a>
        </div>
    )
}