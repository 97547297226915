import { WithChildren } from "models/WithChildren";
import { Children } from "react";

import './rows.module.scss';

export const Rows = ({children}: WithChildren): JSX.Element => {
    return (
        <>
            <div className={'rows'}>
                {children}
            </div>
            {/* <style>
                {`
                    .rows > *{
                        width: ${100 / Children.toArray(children).length}%;
                    }
                `}
            </style> */}
        </>
    )
}