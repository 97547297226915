import React from 'react';
import { BrowserRouter, Link, Navigate, Route, Routes } from 'react-router-dom';

// @ts-ignore
import FeatherIcons from 'feather-icons-react';

import moment from 'moment';

import { Homepage, PrivacyPage } from 'pages';

import { UserAgreementPage } from 'pages/UserAgreementPage';


import { Container, Footer, Header, Image, RowItem, Rows } from 'components';

const App = (): JSX.Element => {
    return (
        <BrowserRouter>
            <Container>
                <Header>
                    <a href="/">
                        <Image className={'logo'} src={'/images/logo.png'} />
                    </a>
                </Header>

                <Routes>
                    <Route path="/" element={<Homepage />} />
                    <Route path="/algemene-voorwaarden" element={<UserAgreementPage />} />
                    <Route path="/privacy-policy" element={<PrivacyPage />} />

                    <Route path="*" element={<Navigate to="/" replace />} />
                </Routes>
                
                <Footer>
                    <h4>The Talent Pool</h4>
                    <Rows>
                        <RowItem>
                            <a href="https://www.facebook.com/profile.php?id=100063565479773" target={'_blank'} rel="noreferrer">

                                <FeatherIcons icon={'facebook'} />
                            </a>
                        </RowItem>
                        <RowItem>
                            <a href="https://www.instagram.com/thetalentpool_/" target={'_blank'} rel="noreferrer">
                                <FeatherIcons icon={'instagram'} />
                            </a>
                        </RowItem>
                    </Rows>
                    <Rows>
                        <RowItem>
                            <h5>© {moment().year()}</h5>
                        </RowItem>
                        <RowItem>
                            -
                        </RowItem>
                        <RowItem>
                            <a href='mailto:info@thetalentpool.nl'>info@thetalentpool.nl</a>
                        </RowItem>
                        <RowItem>
                            -
                        </RowItem>
                        <RowItem>
                            <Link to="/privacy-policy">
                                Privacystatement
                            </Link>
                        </RowItem>
                        <RowItem>
                            -
                        </RowItem>
                        <RowItem>
                            <Link to="/algemene-voorwaarden">
                                Algemene voorwaarden
                            </Link>
                        </RowItem>
                    </Rows>
                </Footer>
            </Container>
        </BrowserRouter>
    );
}

export default App;