import { Column, Columns, Image, Section } from "components";
import { useEffect } from "react";

export const UserAgreementPage = () => {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    
    return (
        <>
            <Section>
                <Image src="/images/assets/transparent-HQ.png" widthVal='25%' style={{ display: 'block', margin: '0 auto' }} />
            </Section>
            <Section>
                <Columns>
                    <Column>
                        <h1>Gebruiksvoorwaarden The Talent Pool</h1>

                        <p>The Talent Pool is bij de Kamer van Koophandel geregistreerd onder nummer 75194899.</p>

                        <h3>Artikel 1 - Algemeen</h3>
                        <ol style={{ listStyleType: 'decimal' }}>
                            <li>
                                In deze algemene voorwaarden worden de hiernavolgende termen in de navolgende betekenis gebruikt, tenzij uitdrukkelijk anders is aangegeven.
                            </li>
                            <li>
                                Aanbod: het aanbod van een Gebruiker aan een andere Gebruiker.
                            </li>
                            <li>
                                Diensten: het ter beschikking stellen van de Website en/of Applicatie en het publiceren van een Aanbod.
                            </li>
                            <li>
                                Gebruiker of Gebruikers: de natuurlijke persoon in de uitoefening van beroep of bedrijf die gebruik maakt van de Website en/of Applicatie.
                            </li>
                            <li>
                                Overeenkomst: elke Overeenkomst en andere verplichtingen tussen Gebruiker en The Talent Pool, alsmede voorstellen van The Talent Pool voor Diensten die door The Talent Pool aan Gebruiker worden verstrekt en die door Gebruiker worden aanvaard en zijn geaccepteerd en uitgevoerd door The Talent Pool.
                            </li>
                            <li>
                                The Talent Pool: de dienstverlener die het gebruik van de Website en/of Applicatie ter beschikking stelt aan een Gebruiker.
                            </li>
                            <li>
                                Website: de website die door The Talent Pool ten behoeve van Gebruiker ter beschikking wordt gesteld, is: https://www.thetalentpool.nl/app.
                            </li>
                        </ol>


                        <h3>Artikel 2 - Toepasselijkheid</h3>
                        <ol style={{ listStyleType: 'decimal' }}>
                            <li>
                                Deze gebruiksvoorwaarden zijn van toepassing op elke Overeenkomst tussen The Talent Pool en Gebruiker en op het gebruik van de Website en/of Applicatie door elke Gebruiker.
                            </li>
                            <li>
                                Afwijking van deze algemene voorwaarden is niet mogelijk.
                            </li>
                            <li>
                                De algemene voorwaarden van Gebruiker in de uitoefening van beroep of bedrijf zijn uitgesloten.
                            </li>
                            <li>
                                The Talent Pool gaat zorgvuldig om met de (persoons)gegevens van Gebruikers. Verwerking van persoonsgegevens zal uitsluitend plaatsvinden in het kader van de uitvoering van de diensten van The Talent Pool. The Talent Pool zal de persoonsgegevens niet voor enig ander doel verwerken en nimmer langer bewaren dan noodzakelijk. Meer informatie is te vinden in het Privacy statement van The Talent Pool.
                            </li>
                            <li>
                                De Website en/of Applicatie van The Talent Pool is in beginsel niet toegankelijk voor minderjarigen onder de 12 jaar, tenzij de ouders en/of wettelijke vertegenwoordigers van de minderjarigen onder de 12 jaar het account beheren.
                            </li>
                            <li>
                                Als aanbieder van de Website en/of Applicatie, is The Talent Pool geen contractspartij bij de overeenkomst die tot stand kan komen tussen Gebruikers onderling.
                            </li>
                            <li>
                                The Talent Pool heeft geen controle over, en biedt nimmer garantie voor de kwaliteit, geschiktheid, volledigheid,  juistheid, (il)legaliteit, beoordelingen en/of recensies van het Aanbod, of de Gebruiker die het betreffende Aanbod heeft geplaatst. The Talent Pool wijst erop dat elke Gebruiker een zelfstandige onderzoeksplicht heeft om te onderzoeken of het getoonde Aanbod voldoet aan het hierboven genoemde. Eventuele afbeeldingen bij het Aanbod zijn enkel en alleen bedoeld als weergave. Een Gebruiker kan geen rechten ontlenen aan deze afbeelding(en), en is nimmer aan te merken als een goedgekeurde afbeelding door The Talent Pool.
                            </li>
                            <li>
                                De inhoud van het geplaatste Aanbod of delen daarvan kunnen weergegeven worden op andere websites, applicaties, e-mails, social media en in andere online en offline advertenties.
                            </li>
                            <li>
                                De Website en/of het Aanbod kan links bevatten naar websites van derden. Op de websites van derden kunnen andere cookies en/of privacy statements van toepassing zijn. The Talent Pool is niet verantwoordelijk of aansprakelijk voor de beschikbaarheid of juistheid van de (inhoud van de) websites van derden. Een link op de Website en/of Applicatie naar een derde is nimmer aan te merken als een goedkeuring van The Talent Pool van de (diensten van) de derde.
                            </li>
                            <li>
                                Het is Gebruiker verboden om de Overeenkomst over te dragen aan een derde, tenzij uitdrukkelijk en schriftelijk anders overeengekomen.
                            </li>
                        </ol>

                        <h3>Artikel 3 - Gebruik van de Website en/of Applicatie</h3>
                        <ol style={{ listStyleType: 'decimal' }}>
                            <li>
                                The Talent Pool kan nadere beperkingen of voorwaarden stellen aan de toegang tot en gebruik van bepaalde delen of functies van de Website en/of Applicatie zoals onder meer maar niet beperkt tot het aanmaken van een account, het voltooien van een verificatieproces en het voldoen aan specifieke kwaliteits- of geschiktheidscriteria.
                            </li>
                            <li>
                                2Het verifiëren van Gebruikers is niet waterdicht. Desondanks neemt The Talent Pool alle in redelijkheid te nemen maatregelen die van haar verwacht kunnen worden met betrekking tot het verificatieproces. Een Gebruiker kan dienaangaande verplicht zijn om een vorm van (overheids)identificatie of andere informatie te verstrekken. Tevens heeft The Talent Pool het recht om Gebruikers te screenen op basis van openbare registers van strafrechtelijke veroordelingen (indien beschikbaar).
                            </li>
                            <li>
                                Het is elke Gebruiker verboden om de (inhoud) van de Website en/of Applicatie te kopiëren en/of op andere te reproduceren, te openbaren of wijzigen zonder voorafgaande schriftelijke toestemming van The Talent Pool. Hieronder wordt tevens verstaan de inhoud van de aanbiedingen.
                            </li>
                            <li>
                                De (inhoud van) de Website en/of Applicatie en het Aanbod kan beschermd worden door intellectuele eigendomsrechten en/of handelsmerken. Door de Website en/of Applicatie te gebruiken gaat elke Gebruiker ermee akkoord dat de intellectuele eigendomsrechten berusten bij The Talent Pool en/of de Gebruiker die het Aanbod heeft geplaatst.
                            </li>
                            <li>
                                Het is Gebruiker uitdrukkelijk verboden om inbreuk te maken op de intellectuele eigendomsrechten van The Talent Pool en derden, alsmede op de goede naam van The Talent Pool. Alle intellectuele eigendomsrechten en auteursrechten van de Website en/of Applicatie, waaronder de grafische ontwerpen, ideeën en dergelijke berusten uitsluitend bij The Talent Pool en worden uitdrukkelijk niet overgedragen aan Gebruiker. Het is een Gebruiker verboden om de inhoud te gebruiken, aan te passen, te kopiëren, in licentie te geven, verkopen of anderszins te exploiteren. Een Gebruiker heeft slechts een beperkt gebruiksrecht om gebruik te maken van de Website en/of Applicatie.
                            </li>
                            <li>
                                Gebruiker is het voorts niet toegestaan om de (persoons)gegevens van Gebruikers die een Aanbod op de Website en/of Applicatie hebben geplaatst te verwerken, verzamelen of op andere wijze te gebruiken ten behoeve van acquisitie of het aanbieden eigen diensten of producten.
                            </li>
                            <li>
                                Gebruiker draagt er zorg voor dat alle gegevens, waarvan The Talent Pool aangeeft dat deze noodzakelijk zijn of waarvan Gebruiker redelijkerwijs behoort te begrijpen dat deze noodzakelijk zijn voor de toegang en/of het gebruik van de Website en/of Applicatie.
                            </li>
                            <li>
                                The Talent Pool is niet aansprakelijk voor schade, van welke aard ook, die is ontstaan doordat The Talent Pool is uit gegaan van door de Gebruiker verstrekte onjuiste en/of onvolledige gegevens, tenzij deze onjuistheid of onvolledigheid voor The Talent Pool bekend was.
                            </li>
                            <li>
                                Een Gebruiker heeft toegang tot de informatie zoals gepubliceerd en weergegeven op de Website en/of Applicatie. Gebruiker is te allen tijde zelf verantwoordelijk voor alle gegevens en informatie die hij op de Website en/of Applicatie, het Aanbod of in zijn account plaatst. Indien Gebruiker vermoedt dat de door hem verstrekte gegevens onjuist of onvolledig zijn, zal Gebruiker The Talent Pool onverwijld informeren en alsnog de juiste informatie vertrekken. Gebruiker dient zelf zijn gegevens up-to-date te houden en kan daartoe zijn gegevens in zijn eigen account aanpassen.
                            </li>
                            <li>
                                The Talent Pool is gerechtigd om (delen van de) Website en/of Applicatie te wijzigen, alsmede haar Diensten. Gebruiker zal van eventuele wijzigingen tijdig op de hoogte gebracht worden.
                            </li>
                            <li>
                                The Talent Pool kan middels Artificial Intellegence (AI) woorden c.q. zinsdelen detecteren van de Gebruikers, en/of gebruikers met elkaar matchen en/of voorstellen te doen op basis van de interesses van gebruikers.. Hierbij wordt gebruik gemaakt van software ten behoeve van het automatiseren van de reacties. AI fungeert als intelligente 'digital medewerkers’ om specifieke taken na te bootsen, te handelen en acteren zoals mensen de taak zouden uitvoeren.
                            </li>
                        </ol>

                        <h3>Artikel 4 - Het account</h3>
                        <ol style={{ listStyleType: 'decimal' }}>
                            <li>
                                Elke Gebruiker dient een account aan te maken ten behoeve van de toegang en het gebruik van bepaalde functies van de Website en/of Applicatie, zoals het publiceren of bekijken van een Aanbod. Voor de zakelijke gebruiker geldt dat de persoon die de registratie verricht, verklaart en garandeert dat hij/zij de bevoegdheid heeft om de onderneming juridisch te binden en alle benodigde toestemmingen en licenties verschaft aan The Talent Pool die nodig zijn ten behoeve van de juiste uitvoering van de Overeenkomst.
                            </li>
                            <li>
                                Het account kan geregistreerd worden met een e-mailadres en een wachtwoord en/of door middel van social login.
                            </li>
                            <li>
                                De Gebruiker is verplicht om tijdens de registratie van het account juiste en  volledige informatie te verstrekken en om het account te allen tijde up-to-date houden.
                            </li>
                            <li>
                                Elke Gebruiker mag slechts één account registreren tenzij uitdrukkelijk en schriftelijk anders overeengekomen. Het account is persoonlijk en mag niet overgedragen worden.
                            </li>
                            <li>
                                De Gebruiker is zelf verantwoordelijk voor zijn of haar inloggegevens en dient deze inloggegevens niet aan derden te verstrekken. Indien een Gebruiker vermoedt dat de inloggegevens zijn kwijtgeraakt, gestolen of mogelijk sprake is van ongeoorloofd gebruik van het account, dient Gebruiker per direct contact op te nemen met The Talent Pool. Gebruiker is zelf aansprakelijk voor alle activiteiten die worden uitgevoerd via het eigen account, tenzij de Gebruiker kan aantonen dat hij/zij niet nalatig is geweest (het niet melden van het ongeoorloofde gebruik of verlies van inloggegevens).
                            </li>
                            <li>
                                Slechts nadat het account is aangemaakt, kan een Gebruiker acties uitvoeren op de Website en/of Applicatie.
                            </li>
                        </ol>

                        <h3>Artikel 5 - Plaatsen van een Aanbod</h3>
                        <ol style={{ listStyleType: 'decimal' }}>
                            <li>
                                Elke Gebruiker heeft een zelfstandige verantwoordelijkheid voor alle inhoud die de Gebruiker beschikbaar stelt op of via de Website en/of Applicatie. Door het plaatsen van informatie op de Website en/of Applicatie garandeert de gebruiker dat zij de eigenaar is van alle gegevens die zij publiceert, dan wel gemachtigd/bevoegd is om dergelijke informatie te publiceren/ openbare op de Website en/of Applicatie. Gebruiker staat er voor in dat alle informatie en/of gegevens die zij via de Website en/of Applicatie publiceert, geen inbreuk maakt op de intellectuele eigendomsrechten van een derde, noch een schending van de toepasselijke wet/ en regelgeving.
                            </li>
                            <li>
                                Het is elke Gebruiker voorts verboden om een Aanbod te uploaden, reacties te plaatsen of op andere wijze content op de Website en/of Applicatie te publiceren en/of te verspreiden via de Website en/of Applicatie in strijd met de Nederlandse wet- en regelgeving, waaronder in elk geval verstaan: gegevens zonder toestemming van de auteursrechthebbende, smadelijke informatie, of informatie die beledigt, intimideert, lasterlijk is, discrimineert, bedreigt, racistisch is van aard, gewelddadig, haat zaaiend is, aanstootgevend, obsceen, en informatie die kinder- of anderszins (strafbare) pornografie bevat. Ook informatie die de privacy van derden schendt (tevens stalking), alsook torrents, virussen, spam, backlinks en hyperlinks (die naar dergelijke informatie verwijst op websites van derden), leningen, loterijen en/of gokken (gambling), drugs alsmede alle andersoortige schadelijke en/of illegale inhoud die het internet- of e-mailverkeer van derden schade kan toebrengen of de Website en/of Applicatie.  The Talent Pool kan de geplaatste content, die een of meerdere van bovengenoemde inhoud bevat verwijderen. The Talent Pool zal de betreffende Gebruiker die de content heeft geplaatst hiervan op de hoogte stellen.
                            </li>
                            <li>
                                The Talent Pool respecteert het auteursrecht van derden en verwacht van de Gebruikers van de Website  en/of Applicatie dat zij hetzelfde doen.
                            </li>
                            <li>
                                Elke Gebruiker gaat ermee akkoord door informatie en/of gegevens via de Website en/of Applicatie te uploaden, publiceren of op andere wijze ter beschikking te stellen aan derden, dat Gebruiker een kosteloze, niet-exclusieve, wereldwijde, sublicentieerbare en overdraagbare (gebruiks)licentie voor de betreffende content verstrekt ten behoeve van de exploitatie- en commercialiseringsrechten.. Hieronder wordt verstaan, maar is niet beperkt tot het recht van The Talent Pool om de  content te gebruiken, kopiëren, publiceren, wijzigen en (delen daarvan) te delen en/of te promoten ten behoeve van de Website en/of Applicatie op internet en/of (social media) kanalen.
                            </li>
                            <li>
                                Indien een Gebruiker in de uitoefening van beroep of bedrijf een dienst aanbiedt via een Aanbod is Gebruiker verplicht om (i) de volledige prijs te benoemen, inclusief alle onvermijdbare kosten en (ii) de prijs inclusief btw te tonen.
                            </li>
                            <li>
                                Indien Gebruiker vermoedt dat de door hem verstrekte gegevens onjuist of onvolledig zijn, zal Gebruiker The Talent Pool onverwijld informeren en alsnog de juiste informatie vertrekken en/of eventuele gebreken zelf herstellen.
                            </li>
                            <li>
                                The Talent Pool is niet aansprakelijk voor schade veroorzaakt door complicaties in verband met de beschikbaarheid of werking van informatie van derden, alsmede de schade die het gevolg is van gebruik van de Website en/of Applicatie in strijd met voorwaarden of andere doeleinden dan waarvoor de Website en/of Applicatie is bedoeld.
                            </li>
                        </ol>

                        <h3>Artikel 6 - Opschorting en ontbinding</h3>
                        <ol style={{ listStyleType: 'decimal' }}>
                            <li>
                                The Talent Pool heeft het recht om het Aanbod van Gebruiker en/of haar andere Diensten (tijdelijk) op te schorten indien de inhoud van het Aanbod in strijd is met deze voorwaarden, of het Aanbod misleidend is of anderszins schade veroorzaakt aan andere Gebruikers of derden.
                            </li>
                            <li>
                                Indien Gebruiker in strijd handelt met deze algemene voorwaarden, zal The Talent Pool de betreffende Gebruiker een officiële waarschuwing geven alvorens over te gaan tot het (tijdelijk) opschorten van haar Diensten zoals bedoeld in lid 1 van dit artikel.
                            </li>
                        </ol>

                        <h3>Artikel 7 - Notice and Takedown</h3>
                        <ol style={{ listStyleType: 'decimal' }}>
                            <li>
                                Ingeval van (mogelijke) strafbare handelingen is The Talent Pool verplicht hiervan aangifte te doen en de door Gebruiker verstrekte gegevens aan de bevoegde instanties te overhandigen, alsmede alle handelingen te verrichten die van haar gevraagd worden in het kader van het onderzoek.  The Talent Pool komt het recht toe Gebruiker de toegang tot de Website en/of Applicatie te ontzeggen en/of het gebruik van de Website en/of Applicatie te beëindigen.
                            </li>
                            <li>
                                Naast de verplichtingen uit de wet is schade welke ontstaat door ondeskundigheid of het niet handelen conform bovenstaande punten komt voor rekening en risico van Gebruiker.
                            </li>
                            <li>
                                Gebruiker is zelf verantwoordelijk voor de juiste beveiliging van het (mobiele) apparaat waarop hij de Website en/of Applicatie gebruikt, alsmede voor het beveiligen en geheimhouden van zijn/haar eigen inloggegevens.
                            </li>
                            <li>
                                Indien en voor zover er sprake is van een inbreuk op rechten van The Talent Pool of derden en/of onrechtmatig handelen door Gebruiker, is The Talent Pool tevens gerechtigd om het account van Gebruiker te verwijderen of op andere wijze beperkingen te stellen aan het gebruik van het account van Gebruiker zoals het beperken of uitschakelen van het plaatsen van aanbiedingen, reacties en meer. The Talent Pool zal eventuele inbreukmakende/schadelijke informatie per direct verwijderen. In geen geval is The Talent Pool aansprakelijk voor de geleden schade van Gebruiker, van welke aard dan ook ten gevolge van het handelen van Gebruiker.
                            </li>
                            <li>
                                Elke Gebruiker kan aanwijzingen en/of klachten melden bij The Talent Pool over inbreuk makende of illegale aanbiedingen, of klachten over andere Gebruikers. Een melding kan gedaan worden via [HOE MELDING MAKEN?]
                            </li>
                        </ol>

                        <h3>Artikel 8 - Beschikbaarheid Website en/of Applicatie</h3>
                        <ol style={{ listStyleType: 'decimal' }}>
                            <li>
                                The Talent Pool staat er niet voor in dat de Diensten altijd zullen voldoen aan de vooraf gewekte verwachtingen. The Talent Pool spant zich in om (de toegang tot) de Website en/of Applicatie voor zover mogelijk, ononderbroken aan te bieden aan Gebruiker, doch staat niet in voor de te allen tijde volledige beschikbaarheid van de Website en/of Applicatie. The Talent Pool is gerechtigd indien en voor zover er naar haar oordeel gevaar bestaat voor het foutloos functioneren van de Website en/of Applicatie, het gebruik van de Website en/of Applicatie op te schorten. The Talent Pool is voorts gerechtigd alle maatregelen te nemen die zij redelijkerwijs noodzakelijk acht om een doeltreffend functioneren van de Website en/of Applicatie te kunnen waarborgen.
                            </li>
                            <li>
                                The Talent Pool is niet verantwoordelijk voor (ver)storingen van de internet-, telecommunicatie-infrastructuur die buiten de invloedssfeer van The Talent Pool ligt en welke kunnen leiden tot onderbrekingen in de beschikbaarheid van de Website en/of Applicatie. The Talent Pool kan, tijdelijk en met inachtneming van de gerechtvaardigde belangen van de Gebruikers, de beschikbaarheid van de Website beperken indien The Talent Pool dit noodzakelijk acht gelet op eventuele capaciteitslimieten, de veiligheid/integriteit van de servers van The Talent Pool, of om onderhoud uit te voeren aan de Website en/of Applicatie. The Talent Pool is gerechtigd om onderhoud te verrichten aan de Website en/of Applicatie en kan nieuwe functionaliteiten en introduceren. The Talent Pool zal de Gebruikers zoveel mogelijk op de hoogte stellen van eventuele wijzigingen aan de Website en/of Applicatie, tenzij dergelijke wijzigingen van minimale aard zijn zonder dat dit een wezenlijk effect heeft op eventuele contractuele verplichtingen.
                            </li>
                            <li>
                                The Talent Pool heeft nimmer verantwoordelijkheid over en/of invloed op de uitvoering van de aangeboden diensten door Gebruikers, en geeft geen garantie over de kwaliteit van het aangebodene, de bevoegdheid van een Gebruiker om de dienst aan te bieden, noch over de juistheid en volledigheid van de aangeboden informatie. Elke Gebruiker heeft een zelfstandige verplichting om onderzoek te doen naar de dienst die door een andere Gebruiker wordt aangeboden via het Aanbod. Alle weergegeven informatie en getallen op de Website en/of Applicatie zijn onder voorbehoud  van spel- of typefouten. The Talent Pool is nimmer aansprakelijk indien de dienst niet voldoet aan de verwachtingen van een Gebruiker.
                            </li>
                        </ol>

                        <h3>Artikel 9 - Betaling</h3>
                        <ol style={{ listStyleType: 'decimal' }}>
                            <li>
                                Het recht om gebruik te maken van de Website en/of Applicatie is in beginsel niet onderworpen aan een betalingsverplichting. In de toekomst kan The Talent Pool een betalingsverplichting verbinden aan het gebruik of een deel van het gebruik van de Website en/of Applicatie.
                            </li>
                            <li>
                                Indien en voor zover een Gebruiker dient te betalen voor de toegang, gelden de hiernavolgende betalingsvoorwaarden.
                            </li>
                            <li>
                                Partijen kunnen overeenkomen dat Opdrachtgever een voorschot dient te betalen. Indien een voorschot is overeengekomen, dient Opdrachtgever het voorschot te betalen alvorens een aanvang wordt gemaakt met de uitvoering van de dienstverlening.
                            </li>
                            <li>
                                Opdrachtgever kan geen rechten of verwachtingen ontlenen aan een vooraf afgegeven begroting, tenzij partijen uitdrukkelijk anders zijn overeengekomen.
                            </li>
                            <li>
                                Is gerechtigd om de geldende prijzen en tarieven jaarlijks te verhogen conform de geldende inflatie tarieven. Overige prijswijzigingen gedurende de Overeenkomst zijn slechts mogelijk indien en voor zover deze uitdrukkelijk zijn vastgelegd in de Overeenkomst.
                            </li>
                            <li>
                                Opdrachtgever dient deze kosten ineens, zonder verrekening of opschorting, binnen de opgegeven betaaltermijn zoals vermeld op de factuur te voldoen op het aan haar kenbaar gemaakte rekeningnummer en gegevens van .
                            </li>
                            <li>
                                In geval van liquidatie, insolventie, faillissement, onvrijwillige liquidatie of verzoek tot betaling jegens Opdrachtgever wordt de betaling en alle andere verplichtingen van Opdrachtgever uit hoofde van de Overeenkomst onmiddellijk opeisbaar.
                            </li>
                        </ol>

                        <h3>Artikel 10 - Incassobeleid</h3>
                        <ol style={{ listStyleType: 'decimal' }}>
                            <li>
                                Wanneer Opdrachtgever niet aan haar betalingsverplichting voldoet, en niet binnen de daarvoor gestelde betalingstermijn heeft voldaan aan haar verplichting, is Opdrachtgever zijnde een Bedrijf van rechtswege in verzuim. Opdrachtgever zijnde een Consument zal eerst een schriftelijke aanmaning ontvangen met een termijn van 14 dagen na de datum van de aanmaning om alsnog aan de betalingsverplichting te voldoen met daarbij een opgave van de buitengerechtelijke kosten indien Consument binnen die termijn niet aan haar verplichtingen voldoet, alvorens zij in verzuim raakt.
                            </li>
                            <li>
                                Vanaf de datum dat Opdrachtgever in verzuim is, zal  zonder nadere ingebrekestelling recht hebben op de wettelijke handelsrente vanaf de eerste verzuimdag tot algehele voldoening, en vergoeding van de buitengerechtelijke kosten conform artikel 6:96 BW te berekenen volgens de staffel uit het besluit vergoeding voor buitengerechtelijke incassokosten van 1 juli 2012.
                            </li>
                            <li>
                                Indien  meer of hogere kosten heeft gemaakt welke redelijkerwijs noodzakelijk zijn, komen deze kosten in aanmerking voor vergoeding. Ook de integrale gemaakte gerechtelijke en executiekosten zijn voor rekening van Opdrachtgever.
                            </li>
                        </ol>

                        <h3>Artikel 11 - Communicatiefaciliteiten</h3>
                        <ol style={{ listStyleType: 'decimal' }}>
                            <li>
                                The Talent Pool kan Gebruiker in staat stellen om berichten te sturen aan andere gebruikers op het platform. Gebruikers verlenen elkaar het recht om gebruik te maken van de applicatie is in beginsel niet onderworpen aan een betalingsverplichting. Gebruiker is hierbij gerechtigd om gedurende elke maand drie aanbiedingen/producten kosteloos aan te vinken. toestemming om berichten die via de communicatiefaciliteiten aan hen zijn geadresseerd naar hen door te sturen. The Talent Pool zal hierbij geen e-mailadressen of andere contactgegevens van Gebruikers aan andere Gebruikers doorgeven.
                            </li>
                            <li>
                                The Talent Pool adviseert Gebruikers om de nodige voorzichtigheid in acht te nemen bij het versturen van berichten via de website en/of Applicatie. The Talent Pool raadt Gebruikers in het bijzonder aan om geen financiële informatie op te nemen in berichten.
                            </li>
                            <li>
                                De website en/of Applicatie biedt Gebruikers de mogelijkheid om zich op te geven voor het ontvangen van een nieuwsbrief van The Talent Pool die hiervoor de mogelijkheid bieden via de website en/of Applicatie van The Talent Pool. Indien Gebruikers de nieuwsbrief willen ontvangen kunnen ze dat aangeven op het registratieformulier of zich hiervoor aanmelden via een link in de website en/of Applicatie. Na registratie kunnen Gebruikers zich afmelden voor de nieuwsbrief, door te klikken op de link in de nieuwsbrief zelf.
                            </li>
                        </ol>

                        <h3>Artikel 12 - Content en/of Advertenties van derden</h3>
                        <ol style={{ listStyleType: 'decimal' }}>
                            <li>
                                Op de website en/of Applicatie van The Talent Pool kunnen content en/of advertenties worden geplaatst en/of ter beschikking gesteld worden door derden. The Talent Pool is niet aansprakelijk en/of verantwoordelijk voor de informatie die ter beschikking wordt gesteld via content en/of advertenties op de website en/of Applicaties.
                            </li>
                            <li>
                                The Talent Pool controleert de content van derden, zoals applicaties, teksten, afbeeldingen, vormgeving, databestanden, foto’s, video’s en overig (stilstaand en/of bewegend) beeldmateriaal, geluidsmateriaal, formats, software, merken (inclusief domeinnamen) en overige materialen, niet en de aanwezigheid daarvan op website en/of Applicatie impliceert niet dat The Talent Pool deze content heeft goedgekeurd. The Talent Pool is niet aansprakelijk voor de inhoud en werkwijze van content van derden of voor enig gebruik hiervan door Gebruikers.
                            </li>
                        </ol>

                        <h3>Artikel 13 - Beperking van Aansprakelijkheid</h3>
                        <ol style={{ listStyleType: 'decimal' }}>
                            <li>
                                The Talent Pool is niet aansprakelijk wanneer zij ten gevolge van een overmachtssituatie haar verplichtingen niet kan nakomen, noch kan zij gehouden worden tot het nakomen van enige verplichting, indien zij daartoe gehinderd wordt als gevolg van een omstandigheid die niet is te wijten aan haar schuld, en noch krachtens de wet, een rechtshandeling of in het verkeer geldende opvattingen voor haar rekening komt. Onder overmacht wordt in elk geval verstaan, maar is niet beperkt tot hetgeen daaromtrent in de wet en jurisprudentie wordt begrepen, (i) overmacht van toeleveranciers van The Talent Pool, (ii) het niet naar behoren nakomen van verplichtingen van toeleveranciers, (iii) gebrekkigheid van zaken, apparatuur, programmatuur of materialen van derden, (iv) overheidsmaatregelen, (v) elektriciteitsstoring, (vi) storing van internet, datanetwerk- en telecommunicatiefaciliteiten (bijvoorbeeld door: cybercriminaliteit en hacking), (vii) brand, (viii) natuurrampen, (ix) oorlog en terroristische aanslagen, (x) algemene vervoersproblemen, (xi)  werkstakingen in het bedrijf van The Talent Pool en (xii) overige situaties die naar het oordeel van The Talent Pool buiten haar invloedssfeer vallen die de nakoming van haar verplichtingen tijdelijk of blijvend verhinderen.
                            </li>
                            <li>
                                Indien en voor zover enige wettelijke bepaling zich hiertegen niet verzet, sluit The Talent Pool alle aansprakelijkheid uit voor schade geleden door Gebruiker op grond van (i) het gebruik van de Diensten van The Talent Pool, (ii) de uitvoering van de diensten van (andere) Gebruikers via de Website en/of Applicatie, (iii) het niet of niet volledig of veilig beschikbaar zijn van de Website en/of Applicatie, (iv) veranderingen in, aan of op de Website en/of Applicatie of Diensten van The Talent Pool en (v) eventuele onjuiste en/of onvolledige informatie op de Website en/of Applicatie.
                            </li>
                            <li>
                                The Talent Pool sluit alle gevolgschade uit.
                            </li>
                            <li>
                                Indien en voor zover The Talent Pool aansprakelijk blijkt te zijn, is haar aansprakelijkheid beperkt tot een maximaal bedrag van 250 euro.
                            </li>
                            <li>
                                Alle aanspraken van Gebruiker wegens tekortschieten aan de zijde van The Talent Pool vervallen indien deze niet schriftelijk en gemotiveerd zijn gemeld bij The Talent Pool binnen een jaar nadat Gebruiker bekend was of redelijkerwijs bekend kon zijn met de feiten waarop zij haar aanspraken baseert. Een jaar na het beëindigen van de Overeenkomst tussen partijen vervalt de aansprakelijkheid van The Talent Pool.
                            </li>
                        </ol>

                        <h3>Artikel 14 - Vrijwaring</h3>
                        <ol style={{ listStyleType: 'decimal' }}>
                            <li>
                                Gebruiker vrijwaart The Talent Pool voor aanspraken van derden ten aanzien van de door hen geleden schade op grond van (i) het gebruik van de diensten van Gebruikers, (ii) het gebruik van Diensten van The Talent Pool, (iii) de onrechtmatige inhoud van het Aanbod, (iv) het sluiten van een overeenkomst op grond van een Aanbod.
                            </li>
                        </ol>

                        <h3>Artikel 15   Klachten</h3>
                        <ol style={{ listStyleType: 'decimal' }}>
                            <li>
                                Indien een Gebruiker niet tevreden is over de Diensten van The Talent Pool, is Gebruiker verplicht om deze klachten zo spoedig mogelijk, maar uiterlijk binnen 7 kalenderdagen na de betreffende aanleiding dat tot de klacht heeft geleid te melden. Klachten kunnen mondeling of schriftelijk gemeld worden via .info@thetalantpool.nl met als onderwerp “Klacht”.
                            </li>
                            <li>
                                De klacht moet door Gebruiker voldoende onderbouwd en/of toegelicht zijn, wil The Talent Pool de klacht in behandeling kunnen nemen.
                            </li>
                            <li>
                                The Talent Pool zal zo spoedig mogelijk, doch uiterlijk binnen 7 kalenderdagen na ontvangst van de klacht inhoudelijk reageren op de klacht.
                            </li>
                            <li>
                                Partijen zullen proberen om gezamenlijk tot een oplossing te komen.
                            </li>
                        </ol>

                        <h3>Artikel 16 - Wijzigingen gebruiksvoorwaarden</h3>
                        <ol style={{ listStyleType: 'decimal' }}>
                            <li>
                                Het staat The Talent Pool vrij om (delen van de) Website en/of Applicatie aan te passen, alsmede de inhoud van haar Diensten en deze algemene voorwaarden. De gewijzigde voorwaarden worden gepubliceerd op de Website en/of Applicatie met de laatste bijgewerkte datum. De Gebruiker zal ook 30 dagen voorafgaand aan de inwerkingtreding van de gewijzigde voorwaarden een e-mail ontvangen met de betreffende wijzigingen. Indien Gebruiker het niet eens is met de inhoud van de wijzigingen kan Gebruiker de Overeenkomst onmiddellijk beëindigen. Gebruiker zal een nadere e-mail ontvangen over de wijze waarop de Overeenkomst beëindigd kan worden. Indien Gebruiker niet tijdig, althans niet vóór de datum waarop de wijzigingen in werking treden verwerpt of op andere wijze bezwaar indient, of de overeenkomst beëindigd gaat Gebruiker akkoord met de gewijzigde voorwaarden. Ook indien Gebruiker vanaf de datum van inwerkingtreding van de wijzigingen de Website en/of Applicatie gebruikt, wordt Gebruiker geacht de gewijzigde voorwaarden geaccepteerd te hebben.
                            </li>
                        </ol>

                        <h3>Artikel 17 - Toepasselijk recht</h3>
                        <ol style={{ listStyleType: 'decimal' }}>
                            <li>
                                Op de rechtsverhouding tussen  en Opdrachtgever is Nederlands recht van toepassing.
                            </li>
                            <li>
                                Ingeval van vertalingen van deze algemene voorwaarden, is de Nederlandse versie leidend.
                            </li>
                            <li>
                                Alle geschillen, ontstaan door of naar aanleiding van de Overeenkomst tussen  en Opdrachtgever, worden beslecht door de bevoegde rechter van rechtbank Amsterdam tenzij bepalingen van dwingend recht een andere bevoegde rechter aanwijzen.
                            </li>
                        </ol>

                        Amsterdam, 4 oktober 2020
                    </Column>
                </Columns>


            </Section>
        </>
    )
}