import { Image } from "components/Image";
import { url } from "inspector";
import { WithChildren } from "../../models/WithChildren";

import './section.module.scss';

type SectionProps = {
    bgImageSrc?: string,
} & WithChildren

export const Section = ({children, bgImageSrc}: SectionProps): JSX.Element => {
    const className = bgImageSrc ? 'has-background-image' : ''
    const style = bgImageSrc ? {backgroundImage: `url('${bgImageSrc}')`} : {}
    
    return (
        <section className={className} style={style}>
            {children}
        </section>
    )
}