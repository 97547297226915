import { Column, Columns, Image, Section } from "components";
import { useEffect } from "react";

export const PrivacyPage = () => {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    
    return (
        <>
            <Section>
                <Image src="/images/assets/transparent-HQ.png" widthVal='25%' style={{ display: 'block', margin: '0 auto' }} />
            </Section>
            <Section>
                <Columns>
                    <Column>
                        <h1>Privacy Statement The Talent Pool</h1>

                        <p>Dit is het privacy statement van The Talent Pool, hierna: The Talent Pool. The Talent Pool is ingeschreven bij de Kamer van Koophandel onder nummer 75194899 . In dit document wordt uitgelegd hoe The Talent Pool omgaat met het verwerken van uw persoonsgegevens. Bij de verwerking van uw persoonsgegevens betracht The Talent Pool de grootst mogelijke zorgvuldigheid.</p>

                        <h3>Verkrijging persoonsgegevens:</h3>
                        <p>Indien u gebruik maakt van de diensten van The Talent Pool, verstrekt u zelf een aantal persoonsgegevens aan The Talent Pool, of worden persoonsgegevens van u verkregen in het kader van de overeenkomst. Onder persoonsgegevens wordt verstaan alle gegevens die betrekking hebben op geïdentificeerde of identificeerbare natuurlijke persoon.</p>

                        <h3>Categorieën persoonsgegevens</h3>
                        <p>The Talent Pool verwerkt de volgende categorieën van persoonsgegevens:
                            <ul style={{ listStyleType: 'initial' }}>
                                <li>Contact- en NAW-gegevens, waaronder locatiegegevens, telefoonnummer, e-mailadres;</li>
                                <li>Gegevens over hobby's en interesses en andere daarmee samenhangende bijzondere categorieënpersoonsgegevens.</li>
                                <li>Financiële gegevens;</li>
                                <li>Accountgegevens, waaronder uw wachtwoord en gebruiksnaam;</li>
                                <li>Overige door u zelf verstrekte persoonsgegevens.</li>
                            </ul>
                        </p>

                        <h3>Doel van de verwerking</h3>
                        <p>De persoonsgegevens die door The Talent Pool verwerkt worden, hebben ten doel om:
                            <ul style={{ listStyleType: 'initial' }}>
                                <li>contact met u op te nemen om u te informeren over de door u afgenomen diensten en de uitvoering daarvan;</li>
                                <li>haar diensten te kunnen uitvoeren;</li>
                                <li>haar dienstverlening te verbeteren;</li>
                                <li>betalingen te kunnen verrichten;</li>
                                <li>wettelijke verplichtingen na te kunnen komen;</li>
                                <li>marketing en communicatie uitingen te kunnen doen, waaronder het verbeteren van de website door middel van het analyseren van bezoekersgedrag op de website;</li>
                                <li>nieuwsbrieven te kunnen versturen;</li>
                                <li>met derden gegevens uit te wisselen ten behoeve van de uitvoering van de dienstverlening, voor zover dit noodzakelijk is.</li>
                            </ul>
                        </p>

                        <h3>Grondslag verwerking</h3>
                        <p>
                            Het verwerken van persoonsgegevens is slechts mogelijk op grond van de hiernavolgende grondslagen: (i) wettelijke verplichting, (ii) uitvoering overeenkomst, (iii) verkregen (uitdrukkelijke) toestemming van de betrokkenen, en (iv) gerechtvaardigd belang. Bij het verlenen van de diensten van The Talent Pool  worden persoonsgegevens verwerkt. The Talent Pool verwerkt uitsluitend gegevens die The Talent Pool noodzakelijk acht voor (het verbeteren van) de dienstverlening en gaat zorgvuldig om met de (persoons)gegevens die zij over u en uw gebruik van de diensten heeft verzameld. De grondslag voor het verwerken van deze gegevens is de overeenkomst die u met The Talent Pool  bent aangegaan. Ook kunnen er persoonsgegevens van u verwerkt worden door het bezoeken van de website www.thetalentpool.nl indien u hiervoor toestemming hebt gegeven.
                        </p>

                        <h3>Noodzaak verwerking</h3>
                        <p>
                            De verwerking van uw persoonsgegevens is noodzakelijk om de dienstverlening uit te kunnen voeren. De diensten die The Talent Pool biedt, kunnen zonder het verwerken van uw persoonsgegevens niet volledig uitgevoerd worden. Indien voor specifieke doeleinden met betrekking tot de persoonsgegevens uw expliciete toestemming is vereist, dient u hiervoor separaat toestemming te geven.
                        </p>

                        <h3>Geautomatiseerde besluitvorming</h3>
                        <p>
                            Er is geen sprake van geautomatiseerde besluitvorming.
                        </p>

                        <h3>Bewaartermijn</h3>
                        <p>
                            De door The Talent Pool verwerkte persoonsgegevens worden bewaard conform de relevante wet- en regelgeving. Indien een langere bewaartermijn noodzakelijk is op grond van wet- of regelgeving, worden de persoonsgegevens langer bewaard conform deze eisen. Alle (verkregen) persoonsgegevens worden niet langer bewaard dan strikt noodzakelijk.
                        </p>

                        <h3>Verwerking door derden</h3>
                        <p>
                            The Talent Pool deelt persoonsgegevens slechts met derden indien dit strikt noodzakelijk is voor de uitvoering van een overeenkomst en om te voldoen aan relevante wet- en regelgeving. Er worden geen persoonsgegevens verkocht. Op The Talent Pool kan een wettelijke verplichting rusten om persoonsgegevens met derden te delen. Indien met derden persoonsgegevens worden gedeeld, worden hiervoor verwerkersovereenkomsten gesloten. De derden waarmee persoonsgegevens gedeeld worden zijn:
                            <ul style={{ listStyleType: 'initial' }}>
                                <li>Softwareleveranciers, ten behoeve van de uitvoering van de overeenkomst. De categorieën van persoonsgegevens die verwerkt worden zijn de contact- en NAW-gegevens.</li>
                                <li>Websitebeheerder, ten behoeve van uitvoering van de overeenkomst. De categorieën van persoonsgegevens die verwerkt worden zijn de contact- en NAW-gegevens.</li>
                            </ul>
                        </p>


                        <h3>Beveiliging van Persoonsgegevens</h3>
                        <p>
                            The Talent Pool neemt het beschermen van uw persoonsgegevens serieus en neemt dienaangaande, rekening houdend met de stand van de techniek, uitvoeringskosten, alsook met de aard, omvang, context en de verwerkingsdoeleinden en de qua waarschijnlijkheid en ernst uiteenlopende risico’s voor de rechten en vrijheden van personen, passende technische en organisatorische maatregelen om een op het risico afgestemd beveiligingsniveau te waarborgen.
                        </p>

                        <h3>Jonger dan 16 jaar?</h3>
                        <p>
                            Als u minderjarig bent, dus jonger dan 16 jaar, dan mag u alleen toestemming geven voor het verwerken van uw persoonsgegevens met toestemming van een van uw ouders of wettelijke voogd. Het is dan belangrijk dat uw ouder(s) of uw voogd dit statement leest. Zij kunnen ook de rechten uitoefenen die u hebt met betrekking tot de persoonsgegevens die u bij ons achterlaat, zoals het recht om u te verzetten tegen de (verdere) verwerking van uw persoonsgegevens of het recht op inzage in en correctie van uw gegevens.
                        </p>

                        <h3>Disclaimer</h3>
                        <p>
                            Door het gebruiken van de website stemt u in met de disclaimer. The Talent Pool behoudt zich het recht voor te allen tijde de inhoud van haar website en/of deze disclaimer te wijzigen zonder haar klanten en/of gebruikers van de website over deze wijziging te informeren. De inhoud van de website is met de grootst mogelijke zorgvuldigheid samengesteld, maar kan desondanks mogelijke onjuistheden bevatten of onvolledig zijn. The Talent Pool aanvaardt op geen enkele wijze enig aansprakelijkheid voor schade die is ontstaan door, of voortvloeit uit het gebruik van de website. Het gebruiken van deze website komt geheel voor eigen rekening en risico van een gebruiker van de website. Aan de inhoud van de website kunnen geen rechten ontleend worden. Alle teksten op de website zijn auteursrechtelijk beschermd en eigendom van The Talent Pool voor zover deze niet aan derden toebehoren.
                        </p>

                        <h3>Websites en diensten van derden</h3>
                        <p>
                            De diensten verwijzen mogelijk door naar websites of diensten van derden. De handelswijzen met betrekking tot privacy van die derde partijen vallen niet onder deze Privacy Statement. Wij raden u aan om het privacybeleid van deze websites en diensten van derden ter kennis te nemen om inzicht te krijgen in hun handelswijzen.
                        </p>
                        <p>
                            Als u de diensten koppelt aan een dienst van derden (zoals Facebook, Twitter of een andere applicatie van een derde partij) of anderzijds uw account koppelt aan een externe dienst, verzoekt en autoriseert u ons om namens u gegevens te delen met, of toegang te verlenen tot, een dergelijke derde partij (zoals uw gebruikersnaam, het feit dat uw verbinding afkomstig is van onze diensten en andere relevante gebruiks- en diagnostische informatie). We sturen mogelijk ook informatie over de content die u kijkt of uw activiteiten op onze diensten naar dergelijke derde partijen.
                        </p>

                        <h3>Cookieverklaring</h3>
                        <p>
                            The Talent Pool maakt gebruik van technische en functionele cookies om de website te optimaliseren. Cookies zijn kleine tekstbestanden die door het bezoek aan een website worden meegestuurd om gebruikerservaringen van bezoekers efficiënter te maken. The Talent Pool mag, volgens de wet, cookies op uw apparaat opslaan als deze cookies strikt noodzakelijk zijn om de website te kunnen gebruiken. Voor andere soort cookies is uw toestemming nodig. The Talent Pool raadt u aan om de cookies te accepteren in verband met de gebruiksvriendelijkheid van de website. Bezoekers van de website hebben een check in mogelijkheid.
                        </p>
                        <p>
                            De cookies die The Talent Pool gebruikt zijn functionele cookies: deze cookies zorgen ervoor dat de website naar behoren werkt. Deze cookies hebben geen gevolgen voor de persoonlijke levenssfeer zodat er geen toestemming gevraagd en verleend hoeft te worden. Met deze cookies wordt bijvoorbeeld uw browserinstellingen opgeslagen zodat onze website het beste bekeken kan worden, of dat de website bereikbaar blijft (load-balancing) maar ook cookies die ervoor zorgen dat er geen andere cookies mogen worden geplaatst (no-follow).
                        </p>

                        <h3>Gebruik social media</h3>
                        <p>
                            The Talent Pool maakt gebruik van cookies van derde partijen om de website te optimaliseren. Sommige cookies worden geplaatst door diensten van derden die op de website worden weergegeven. Met derden wordt bedoeld Google Analytics en social media (LinkedIn, Facebook, Twitter). Op het gebruik van cookies van andere bedrijven (derden) is het privacy- en cookiebeleid van het betreffende bedrijf van toepassing. Wanneer u op de website de social media button aanklikt, wordt een social media cookie geplaatst. Hierdoor kan de social media partij uw IP-adres herkennen zodra u een artikel van de website deelt. Voor de cookies van social media partijen en de data en/of persoonsgegevens die zij hiermee verzamelen, verwijst The Talent Pool u naar de privacy- en cookieverklaringen van deze partijen.
                        </p>

                        <h3>E-mail nieuwsbrief en andere marketing mails</h3>
                        <p>
                            U kunt zich via de website registreren voor een nieuwsbrief en/of voor andere commerciële aanbiedingen. Wanneer u zich registreert, dan vraagt The Talent Pool u om toestemming om uw e-mailadres te verwerken met als doel om u marketing e-mails te sturen met daarin de meest recente informatie over producten en diensten, speciale aanbiedingen en marketingcampagnes. U ontvangt alleen marketing e-mails wanneer u daartoe toestemming heeft gegeven.
                        </p>
                        <p>
                            Uw toestemming omvat ook uw goedkeuring van het feit dat The Talent Pool de inhoud van marketing e-mails kan personaliseren in overeenstemming met uw behoeften en belangen op basis van een eventueel gebruikersprofiel dat voor u is gecreëerd.
                        </p>
                        <p>
                            Uw toestemming voor het verzenden van marketing e-mails kunt door u op elk gewenst moment worden ingetrokken door te klikken op “Uitschrijven” aan het eind van de marketing e-mail. Het intrekken van de toestemming is niet van invloed op de rechtmatigheid van de verwerking die is uitgevoerd voorafgaand aan het intrekken van de toestemming.
                        </p>

                        <h3>Browserinstellingen</h3>
                        <p>
                            Als u niet wilt dat websites cookies plaatsen op uw apparaat waarmee u de website bekijkt, kunt u uw browserinstellingen aanpassen. Voordat een cookie geplaatst wordt, krijgt u een waarschuwing en dient u toestemming te geven voor de cookie. Doet u dat niet, kan dat bijvoorbeeld tot gevolg hebben dat de website minder goed werkt. U kunt de instellingen van uw browser aanpassen zodat uw browser alle cookies en ook de cookies van derde(n) weigert. Ook kunt u geplaatste cookies verwijderen. Hiervoor moet u de instellingen van uw browser aanpassen via voorkeuren en vervolgens kunt u de privacy instellingen aanpassen.
                        </p>
                        <p>
                            Deze privacyverklaring is niet van toepassing op websites van derden die door middel van links met deze website zijn verbonden. The Talent Pool kan niet garanderen dat deze derden op een betrouwbare of veilige manier met uw persoonsgegevens omgaan. The Talent Pool raadt u aan de privacyverklaring van deze websites te lezen alvorens van deze websites gebruik te maken.
                        </p>

                        <h3>Recht van betrokkenen</h3>
                        <p>
                            Als betrokkene heeft u de volgende rechten:
                            <h5>Recht op inzage;</h5>
                            Als betrokkene heeft u het recht om uitsluitsel te krijgen over het al dan niet verwerken van uw persoonsgegevens en, wanneer dat het geval is, om inzage te verkrijgen met betrekking tot de verwerking daarvan.
                            <h5>Recht op rectificatie;</h5>
                            U heeft het recht om rectificatie en aanvulling van onjuiste gegevens te verkrijgen.
                            <h5>Recht op gegevenswissing;</h5>
                            Zonder onredelijke vertraging dient een betrokkene wissing van hem betreffende persoonsgegevens te verkrijgen.
                            <h5>Recht op beperking van de verwerking;</h5>
                            Als betrokkene heeft u het recht op beperking van de verwerking van uw persoonsgegevens. Neem hiervoor contact op met The Talent Pool.
                            <h5>Recht op overdraagbaarheid van gegevens;</h5>
                            Een betrokkene heeft het recht om de hem betreffende persoonsgegevens die hij verstrekt heeft aan The Talent Pool, in een gestructureerde, gangbare en machineleesbare vorm te verkrijgen om deze persoonsgegevens eventueel over te dragen aan een derde. Een betrokkene kan The Talent Pool ook de opdracht geven om deze gegevens rechtstreeks aan een derde over te dragen.
                            <h5>Recht van bezwaar;</h5>
                            De betrokkene heeft te allen tijde het recht om vanwege met zijn specifieke situatie verband houdende redenen bezwaar te maken tegen de verwerking van hem betreffende persoonsgegevens met inbegrip van profilering op basis van die bepalingen. The Talent Pool staakt de verwerking van de persoonsgegevens tenzij er sprake is van dwingende gerechtvaardigde gronden voor de verwerking die zwaarder wegen dan de belangen, vrijheden en rechten van betrokkene die verband houden met de uitoefening van, instelling of onderbouwing van een rechtsvordering.
                        </p>

                        <h3>Klacht</h3>
                        <p>
                            Indien u een klacht heeft over de wijze waarop The Talent Pool met uw persoonsgegevens omgaat, of een van bovengenoemde rechten wenst uit te oefenen, kunt u contact opnemen via de website of per e-mail via info@thetalentpool.nl. Om er zeker van te zijn dat u een recht wenst uit te oefenen verzoekt The Talent Pool u om een kopie van uw identiteitsbewijs mee te sturen. U kunt hierbij uw pasfoto en MRZ (strook met nummers onderaan) zwart maken ter bescherming van uw privacy. The Talent Pool zal zo spoedig mogelijk, doch uiterlijk binnen 4 weken op uw verzoek reageren. Tevens kunt u een klacht indienen bij de Autoriteit Persoonsgegevens. Dit is de bevoegde handhavingsinstantie. U kunt hiervoor contact opnemen via deze link: https://autoriteitpersoonsgegevens.nl/nl/contact-met-de-autoriteit-persoonsgegevens/tip-ons.
                        </p>

                        <h3>Wijzigingen Privacy Statement</h3>
                        <p>
                            The Talent Pool kan te allen tijde de privacyverklaring aanpassen. Op de website staat de meest recente versie gepubliceerd. Houd daarom altijd de website in de gaten voor de meest recente versie. Als het nieuwe privacy statement gevolgen heeft voor de wijze waarop The Talent Pool reeds verzamelde gegevens met betrekking tot u verwerken, dan brengt The Talent Pool u per e-mail daarvan op de hoogte.
                        </p>

                        Deze versie is voor het laatst vastgesteld op 30 september 2021



                    </Column>
                </Columns>


            </Section>
        </>
    )
}