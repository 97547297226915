import { WithChildren } from "models/WithChildren"

import './footer.module.scss';

export const Footer = ({children}: WithChildren): JSX.Element => {
    return (
        <footer>
            {children}
        </footer>
    )
}