import { Columns } from "components/Columns";
import { Column } from "components/Column";
import { Image } from "components/Image";

import SwiperCore, { EffectCoverflow, Navigation, Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/bundle";

import './testamonials.module.scss';

SwiperCore.use([EffectCoverflow, Navigation, Pagination]);
// if you want to use array
const slide_img = [
    {
        club: 'Feyenoord',
        imageSrc: '/images/assets/S_Ellouzi_card@2x.png',
        name: 'Sabrine Ellouzi',
        quote: 'The Talent Pool is een mooi platform om in beeld te komen bij clubs.'
    },
    {
        club: 'Excelsior vrouwen 1',
        imageSrc: '/images/assets/Mank_card@2x.png',
        name: 'Richard Mank',
        quote: 'Mooie overzichtelijke en handige site.'
    },
    {
        club: 'FC Rijnvogels',
        imageSrc: '/images/assets/Hilali_card@2x.png',
        name: 'Soraya Hilali',
        quote: 'Handige app om met verschillende mensen in de voetbal werel contact te leggen.'
    },
    {
        club: 'AFC 021',
        imageSrc: '/images/assets/Curiel_card@2x.png',
        name: 'Gladwin Curiel',
        quote: 'Ik vind The Talent Pool App een leuk platform voor gedreven voetballers die op zoek zijn naar clubs om op een hogere divisie te spelen. Pak je kans en schrijf je in.'
    },
];

export const Testamonials = (): JSX.Element => (
    <Swiper
        effect={"slide"}
        grabCursor={true}
        navigation={true}
        pagination={true}
        loop={true}
        className="testamonials"
    >
        {slide_img.map((slide, index) => {
            return (
                <SwiperSlide key={index}>
                    <Columns isReverse>
                        <Column widthVal={100 / 5 * 3 + '%'}>
                            <div className={'quote'}>
                                <q>{slide.quote}</q>
                                <div className={'quote-meta'}>
                                    <h3>{slide.name}</h3>
                                    <div>{slide.club}</div>
                                </div>
                            </div>
                        </Column>
                        <Column widthVal={100 / 5 * 2 + '%'}>
                            <Image src={slide.imageSrc} widthVal={100 / 10 * 8 + '%'} />
                        </Column>
                    </Columns>
                </SwiperSlide>
            );
        })}
    </Swiper>
)