import { WithChildren } from "models/WithChildren";
import { Children } from "react";
import './columns.module.scss';

type ColumnsProps = {
    isReverse?: boolean;
} & WithChildren

export const Columns = ({ children, isReverse }: ColumnsProps): JSX.Element => {

    return (
        <div className={'columns'} data-is-reverse={isReverse}>
            <style>
                {`
                    .columns > .column{
                        width: ${100 / Children.toArray(children).length}%;
                    }
                `}
            </style>
            {children}
        </div>
    )
}