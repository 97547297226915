import { Column, Columns, DownloadApp, Header, Image, Section, Testamonials } from "components"

export const Homepage = (): JSX.Element => {
    return (
        <>
            <Section bgImageSrc={'/images/assets/goal.png'}>
                <Columns isReverse>
                    <Column verticalCenter>
                        <Image src={'/images/assets/phone_screens.png'} />
                    </Column>
                    <Column sameSpace>
                        <h1 className={'title'}>
                            <span>The</span> social media <span>platform</span>
                            <br />
                            for <span>football players</span> of all classes
                        </h1>
                        <div>
                            <h3>Download the app and get started!</h3>
                            <DownloadApp />
                        </div>
                    </Column>
                </Columns>
            </Section>

            <Section>
                <Testamonials />
            </Section>

            <Section bgImageSrc={'/images/assets/stripe_background.png'}>
                <Columns>
                    <Column>
                        <Image src={'/images/assets/woman.png'} />
                    </Column>
                    <Column verticalCenter>
                        <h2>
                            Make the first move.
                        </h2>
                        <h3>
                            Explore, message, and connect with
                            <br />
                            football professionals in one place.
                        </h3>
                    </Column>
                </Columns>
            </Section>

            <Section>
                <Columns>
                    <Column verticalCenter>
                        <h2>
                            A click away from
                            <br />
                            your next transfer.
                        </h2>
                        <h3>
                            Get approached or approach football clubs,
                            <br />
                            coaches, football players and other professionals
                            <br />
                            to make the next big transfer.
                        </h3>
                    </Column>
                    <Column bgImageSrc={'/images/assets/card_exchange.png'}>
                    </Column>
                </Columns>
            </Section>

            <Section bgImageSrc={'/images/assets/football.png'}>
                <Columns>
                    <Column>
                    </Column>
                    <Column verticalCenter>
                        <h2>
                            Join now the biggest
                            <br />
                            social media platform
                            <br />
                            for football
                        </h2>
                        <h3>Download the app and get started!</h3>
                        <DownloadApp />
                    </Column>
                </Columns>
            </Section>
        </>
    )
}