import './image.module.scss';

type ImageProps = {
    className?: string,
    isBackground?: boolean,
    heightVal?: string,
    src: string,
    style?: object,
    widthVal?: string,
}

export const Image = ({ className, isBackground, src, style, heightVal, widthVal }: ImageProps): JSX.Element => (
    <img className={className} src={src} alt={''} style={{...{height: heightVal || '', width: widthVal || ''}, ...style}} />
)